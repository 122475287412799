import {Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {lastValueFrom} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ValuesService {
    private _baseUrl = environment.baseUrl + 'api/values/';

    storeNames: WritableSignal<string[]> = signal([]);

    constructor(private _http: HttpClient) {}

    async loadStoresNames() {
        if (this.storeNames().length) return;
        const storeNames = await lastValueFrom(this._http.get<string[]>(this._baseUrl + 'stores'));
        this.storeNames.set(storeNames);
    }
}
